import React from "react";

const NoMatch = () => {
  return (
    <div class="grid h-[80vh] px-4 bg-white place-content-center">
      <h1 class="tracking-widest text-gray-500 uppercase">
        404 | Page Not Found
      </h1>
    </div>
  );
};

export default NoMatch;
